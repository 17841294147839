import 'nprogress/nprogress.css';
import NProgress from 'nprogress';
import store from '@/store/index';
import login from './modules/login';
// import landscape from './modules/landscape';  // 横屏路由
// import portrait from './modules/portrait';  // 竖屏路由
import { createRouter, createWebHashHistory } from 'vue-router';
import { ElMessage } from 'element-plus';
import { translate } from '@/utils/i18n';
import { tree } from '@/api/login';

let getRouteFlag = true; // 正在获取菜单
let trees = []

// 根据横屏竖屏加载相应路由
const isLandscape = window.matchMedia('(orientation: landscape)').matches;  // 横屏

// 创建路由
const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes: [
    ...login,
  ]
})

// 路由守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : document.title
  NProgress.start() // 进度条

  // 判断是否为数据中心跳转过来的
  let fromDataCenter = null;
  if (to.query.fromDataCenter) {
    fromDataCenter = to.query.fromDataCenter;
    to.query.fromDataCenter = null;
    to.fullPath = to.path;
  } else if (to.redirectedFrom?.query?.fromDataCenter) {
    fromDataCenter = to.redirectedFrom.query.fromDataCenter;
    to.redirectedFrom.query.fromDataCenter = null;
    to.fullPath = to.path;
  }
  if (fromDataCenter) {
    fromDataCenter = fromDataCenter.replace(/aaannnddd/g, '&'); // 处理特殊字符
    const data = JSON.parse(fromDataCenter);
    store.dispatch('setUserInfo', data.userInfo); // 设置用户信息
  }
  
  // 判断accessToken是否存在
  const accessToken = JSON.parse(localStorage.getItem('accessToken')) || ''
  if (accessToken && accessToken.value) {
    if (to.path == '/login') {
      // 禁止返回登录页
      if (getRouteFlag) {
        getRoute().then(() => {
          router.push({ path: getDirectoryFirstChild(trees[0]) })
        }).catch(() => {
          store.dispatch('logOut')
          ElMessage.warning(translate('暂无权限，请联系后台管理员'))
          next({ path: '/login' })
        })
      } else {
        next({ path: from.path, query: from.query })
      }
    } else {
      if (getRouteFlag) {
        getRoute().then(() => {
          router.push({ path: to.path, query: to.query })
        }).catch(() => {
          store.dispatch('logOut')
          ElMessage.warning(translate('暂无权限，请联系后台管理员'))
          next({ path: '/login' })
        })
      } else {
        next()
      }
    }
  } else {
    if (to.path != '/login') {
      next({ path: '/login' })
      ElMessage.warning('登录状态已过期，请重新登录!')
      store.dispatch('logOut')
    } else {
      next()
    }
  }
})

// 路由守卫
router.afterEach(() => {
  NProgress.done() // 进度条
})

// 获取动态路由
export function getRoute() {
  return new Promise((resolve,reject) => {
    if (!localStorage.getItem('userInfo')) return reject()
    tree().then(res => {
      const portal = res.data.filter(item => { return item.name == '新看板' }); // 过滤其他菜单
      if (!portal.length) reject()
      trees = handleRouteData(portal)
      store.dispatch('setTree', trees)
      trees.forEach(item => {
        router.addRoute(item)
      })
      getRouteFlag = false
      resolve()
    }).catch(() => {})
  })
}

// 处理路由数据
const handleRouteData = function(data){
  data.forEach((item, index, array) => {
    // 如果类型为0目录：
    // redirect为空，默认设置为第一个children的path
    // redirect指向的地址children中没有，则改为第一个children的path
    if (item.type == 0) {
      if (!item.redirect) {
        if (item.children?.length) {
          item.redirect = item.children[0]?.path || ''
        }
      } else {
        if (item.children?.length) {
          const filter = item.children.filter(ic => { return ic.path == item.redirect})
          if (filter.length === 0) {
            item.redirect = item.children[0].path
          }
        }
      }
    }

    // 根据横竖屏设置相应的主组件和页面路径
    let mainComponent = '';
    let pageSrc = '';
    if (isLandscape) {
      mainComponent = 'components/layout/main-landscape';
      pageSrc = `pages/landscape/${item.component}`;
    } else {
      mainComponent = 'components/layout/main-portrait';
      pageSrc = `pages/portrait/${item.component}`;
    }

    // 0目录、1菜单才有component
    if (item.type == 0) {
      array[index].component = () => import(`@/${mainComponent}`)
    } else if (item.type == 1) {
      array[index].component = () => import(`@/${pageSrc}`);
    }

    // 递归渲染子节点
    if (item.children?.length) {
      array[index].children = handleRouteData(array[index].children)
    }
  })
  return data
}

// 获取目录的第一个子路由（类型为菜单1）
export function getDirectoryFirstChild(data) {
  if (data.type == 0) {
    if (data.children?.length) {
      return getDirectoryFirstChild(data.children[0])
    } else {
      return data.path
    }
  } else if (data.type == 1) {
    return data.path
  }
}

export default router