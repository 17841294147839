import { createStore } from 'vuex'
import user from './modules/user'
import common from './modules/common'

const store = createStore({
  modules: {
    user,
    common,
  },
})

export default store
