export default [

  // 登录
  {
    path: '/login',
    name: 'login',
    meta: { title: '数据看板' },
    component: () => import('@/pages/login/index'),
  },

  // 404
  {
    path: '/:pathMatch(.*)*', 
    name: '404',
    meta: { title: '数据看板' },
    component: () => import('@/pages/login/404'), 
  },

  // DIP1.1.0.17（备用）
  {
    path: '/DIP1.1.0.17',
    name: 'DIP1.1.0.17',
    meta: { title: 'DIP生产' },
    component: () => import('@/pages/landscape/DIP/index1.1.0.17'),
  },
  
]