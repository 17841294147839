import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router/index.js'
import store from '@/store/index.js'
import ElementPlus from 'element-plus'
import zhCN from 'element-plus/lib/locale/lang/zh-cn'  // element语言包（简体中文）
import en from 'element-plus/lib/locale/lang/en'       // element语言包（英语）
import th from 'element-plus/lib/locale/lang/th'       // element语言包（泰国语）
import vi from 'element-plus/lib/locale/lang/vi'       // element语言包（越南语）
import i18n from '@/utils/i18n'
import 'element-plus/dist/index.css'
import '@/assets/css/global.css'

const isLandscape = window.matchMedia('(orientation: landscape)').matches;  // 横屏
// const isPortrait = window.matchMedia('(orientation: portrait)').matches;    // 竖屏

if (isLandscape) {
  localStorage.setItem('orientation', 'landscape');
} else {
  localStorage.setItem('orientation', 'portrait');
}


// 配置element语言包
const app = createApp(App)
const language = localStorage.getItem('language') || 'zh-CN'
const messages = {
  'zh-CN': zhCN,
  'en': en,
  'th': th,
  'vi': vi,
}

// 配置app
app
.use(router)
.use(store)
.use(ElementPlus, { locale: messages[language] })
.use(i18n)
.mount('#app')




