export default {
  state: {
    htmlFontSizeRate: 1, // html字体大小比例
  },

  mutations: {

    // 设置html字体大小比例
    SET_HTML_FONT_SIZE_RATE(state, data) {
      state.htmlFontSizeRate = data;
    },

  },

  actions: {

    // 设置html字体大小比例
    setHtmlFontSizeRate(context, data) {
      context.commit('SET_HTML_FONT_SIZE_RATE', data);
    },

  }
}